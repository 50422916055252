<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center toolbar">
        <h1>双方向コミュニケーションサービス</h1>
        <h1> 　{{ roomName }}</h1>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <h1>双方向コミュニケーションサービスへようこそ</h1>

    <v-btn
      elevation="2"
    >
    <router-link :to="{ name: 'select', params: { roomName: roomName } }">
      ボタン画面へ
    </router-link>
    </v-btn>
    <v-btn
      elevation="2"
    >
    <router-link :to="{ name: 'graph', params: { roomName: roomName } }">
      グラフ画面へ
    </router-link> 
    </v-btn>

    <h2>管理者向けページ</h2>

    <v-btn
      elevation="2"
    >
    <router-link :to="{ name: 'setting', params: { roomName: roomName } }">
      設定画面へ
    </router-link> 
    </v-btn>

  </v-app>
</template>

<script>
export default {
  name: 'RoomComponent',
  computed: {
    roomName() {
      return this.$route.params.roomName;
    }
  }
}
</script>

<style>
  .toolbar {
    white-space: nowrap;
    font-size: 68%;
  }
</style>