































































import Vue from 'vue'
import axios from 'axios';

interface Choice {
  id: String,
  roomName: string,
  text: string,
  display: string
}

export default Vue.extend({
  name: 'Selector',

  data() {
    return {
      id_a: '',
      id_b: '',
      id_c: '',
      id_d: '',
      choices: [
      ],
      vbtnwidth: '',
      btnsize: 0,
      vbtnheight: '',
      dis1:'',
      dis2:'',
      dis3:'',
      dis4:'',
    }
  },

  //DOMが生成される前の処理
  mounted() {
    this.getChoices();
  },

  methods: {
    //choicesから中身を分解する関数      
    sparateData(data: Array<Choice>): Array<string> {
      data.forEach(element => {
        if(element.id == 'A' && element.roomName == this.$route.params.roomName){
          this.id_a = element.text
          this.dis1 = element.display
        }
        else if (element.id == 'B' && element.roomName == this.$route.params.roomName){
          this.id_b = element.text
          this.dis2 = element.display
        }
        else if (element.id == 'C' && element.roomName == this.$route.params.roomName){
          this.id_c = element.text
          this.dis3 = element.display
        }
        else if (element.id == 'D' && element.roomName == this.$route.params.roomName){
          this.id_d = element.text
          this.dis4 = element.display
        }
      })
      return [this.id_a, this.id_b, this.id_c, this.id_d]
    },

    // 選択肢取得
    getChoices() {
      let apiUrl = 'https://' + process.env.VUE_APP_API_URL + '/choices';
      axios.get(apiUrl).then(response => {
        this.choices = response.data;
        console.log(this.choices);  
        this.calibrationButtonSize(this.sparateData(response.data));
      });
    },

    // ボタンを押されてた時の処理
    onPressedButton(text: string, buttonName:string) {

      let roomName = this.$route.params.roomName
      // POSTの処理を書いていく
      axios.post('https://' + process.env.VUE_APP_API_URL + '/data', {choice:text, buttonName:buttonName, roomName:roomName})
        .then(response => {
          if (response.status == 200){
            console.log(text + ' has been registered');
          }
          else {
            console.log('Registration failed.');
          }
        })
    },
    // ボタンの幅をいい感じにする関数
    calibrationButtonSize(dataList: Array<string>) {
      // 入力された文字列から1文字ずつ文字を取得する（split()が使えるはず）
      let byteLengthList: Array<number> = [];
      for (let i = 0; i < dataList.length; i++) {
        // バイト数を取得する
        // 英数字半角1Byte、日本語全角は3Byate
        let dyteSize = (new Blob([dataList[i]])).size;
        
        // 文字列のバイト数を合計する
        // もっとも長いバイト数を取得したいだけなので文字列とバイト数のマッピングは行わない
        byteLengthList.push(dyteSize);
      }
      // 4つの選択肢を比較してもっとも大きいバイト数を取得する
      // これで小さい順にソートされる (a, b) => a - b
      // 参考：https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
      let sortedValue = byteLengthList.sort((a, b) => a - b);
      
      // 1バイトあたりNpxで計算する（1byte=8px換算）
      this.vbtnwidth = (sortedValue[sortedValue.length - 1] * 8) + 'px';
      this.btnsize = sortedValue[sortedValue.length - 1] * 8;
      
      // 現在120pxで455px,335px,215pxで折り返しが発生
      // 160pxの場合,615px,455px,295pxで折り返しが発生
      // ボタンが折り返したら横並びを2つにする
      // Windiwのサイズを取得する
      // innerWidthはWindowサイズ12px大きく取得される
      // そのためinnerWidthから12減算する
      let windowSize: number;
      windowSize = window.innerWidth - 12;
      // console.log('windowSize: ' + windowSize + '  x4: ' + (this.btnsize * 4 - 25) + '  this.temp: ' + this.btnsize);
      if((navigator.userAgent.indexOf('iPhone') > 0 && navigator.userAgent.indexOf('iPad') == -1) || navigator.userAgent.indexOf('Android') > 0){
        this.vbtnwidth = '48vw';
        this.vbtnheight = '25vh';
      }
      else{
      // windowの幅がthis.btmsizeの2倍よりも大きく、4倍よりも小さかったら
      if (((this.btnsize * 2 - 25) <= windowSize) && (windowSize < (this.btnsize * 3 - 25))) {
        // console.log('2列');
        this.vbtnheight = '80px';
      }

      if (((this.btnsize * 3 - 25) < windowSize) && (windowSize <= (this.btnsize * 4 - 25))) {
        // console.log('3列');
        this.vbtnwidth = (windowSize / 2 - 25) + 'px';
        this.vbtnheight = '80px';
      }

      if ((this.btnsize * 4 - 25) < windowSize) {
        // console.log('4列');
        this.vbtnwidth = '24vw';
        this.vbtnheight = '';
      }}
    },

    onResize() {
      this.calibrationButtonSize([this.id_a,this.id_b, this.id_c, this.id_d]);
    },
  },
})
