import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    stateData: []
  },
  getters: {
  },
  mutations: {
    setChartdata(state, stateData) {
      state.stateData = stateData;
    }
  },
  actions: {
    getApi({commit}, roomName) {
      let apiUrl = 'https://' + process.env.VUE_APP_API_URL + '/data?roomName=' + roomName;
       axios.get(apiUrl)
      .then(response => {
        console.log('API Response:', response.data);

        let setdata = []
        for(let jsonGraphinfo of response.data){
          if(jsonGraphinfo.choice == 'A'){
            jsonGraphinfo.choice = 'blue'
          }
          else if(jsonGraphinfo.choice == 'B'){
            jsonGraphinfo.choice = 'red'
          }
          else if(jsonGraphinfo.choice == 'C'){
            jsonGraphinfo.choice = 'yellow'
          }
          else if(jsonGraphinfo.choice == 'D'){
            jsonGraphinfo.choice = 'green'
          }
          setdata.push({
            color: jsonGraphinfo.choice,
            y: jsonGraphinfo.data.y,
            roomName: jsonGraphinfo.room_name
          })
          commit('setChartdata',setdata)
        }
      });
    }
  },
  modules: {
  }
})

