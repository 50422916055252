import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ButtonView from '../views/ButtonView.vue'
import AddRoom from '../views/AddRoom.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'room',
    component: AddRoom,
    props: true
  },
  {
    path: '/home/:roomName',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/select/:roomName',
    name: 'select',
    component: ButtonView
  },
  {
    path: '/graph/:roomName',
    name: 'graph',
    component: () => import('../views/ChartGraphView.vue')
  },
  {
    path: '/setting/:roomName',
    name: 'setting',
    component: () => import('../views/SettingView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
